import { useCallback, useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import { Box } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { get, isEmpty, noop } from 'lodash';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import restService from 'services/restService';

import formatPassage from '../formatPassage';

dayjs.extend(utc);

const SearchResults = ({
  rows,
  totalRowCount,
  currentPage = 0,
  setCurrentPage,
  pageSize,
  setPageSize,
  handleAction,
  setEditState,
  onPaginationChange = noop,
}) => {
  const handleEditClick = (scripture) =>
    setEditState({
      modalOpen: true,
      scriptureId: scripture._id,
      scripture,
    });

  const handleDeleteClick = (id) =>
    handleAction({
      modalOpen: true,
      scriptureId: id,
      action: 'delete',
    });

  const columns = [
    {
      field: 'activeAt',
      headerName: 'Active At',
      flex: 1,
      valueGetter: (value, row) => row.activeAt,
    },
    {
      field: 'passage',
      headerName: 'Passage',
      flex: 1,
      valueGetter: (value, row) => row.passage,
    },
    {
      field: 'prompt',
      headerName: 'Prompt',
      flex: 2,
      valueGetter: (value, row) => row.prompt,
    },
    {
      field: 'guide',
      headerName: 'Guide',
      flex: 2,
      valueGetter: (value, row) => row.guide?.title,
    },
    {
      field: 'level',
      headerName: 'Level',
      flex: 2,
      valueGetter: (value, row) => row.level?.title,
    },
    {
      field: 'levelId',
      headerName: 'Level Id',
      flex: 1,
      valueGetter: (value, row) => row.level?._id,
    },
    {
      field: 'introductionTitle',
      headerName: 'Introduction Title',
      flex: 1,
      valueGetter: (value, row) => row.introduction?.title,
    },
    {
      field: 'introductionText',
      headerName: 'Introduction Text',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ width: 200 }}>
          <Markdown rehypePlugins={[rehypeRaw]}>
            {params.row?.introduction?.text}
          </Markdown>
        </Box>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      cellClassName: 'actions',
      getActions: (params) => {
        const today = dayjs().format('YYYY/MM/DD');
        const activeAt = dayjs(params.row?.activeAt).format('YYYY/MM/DD');

        if (today === activeAt) {
          return [];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            title="Edit"
            key={`edit-${params.id}`}
            onClick={() => handleEditClick(params.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            title="Delete"
            key={`delete-${params.id}`}
            onClick={() => handleDeleteClick(params.id)}
            color="inherit"
          />,
        ];
      },
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  }));

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.pageSize === pageSize ? paginationModel.page : 0);

    setPageSize(paginationModel.pageSize);
    onPaginationChange();
  };

  // eslint-disable-next-line no-magic-numbers
  const pageSizeOptions = [5, 10, 20, 30];

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={rows}
        pageSizeOptions={pageSizeOptions}
        onPaginationModelChange={handlePaginationModelChange}
        pagination
        disableColumnFilter
        disableRowSelectionOnClick
        paginationMode="server"
        rowCount={totalRowCount}
        getRowId={(row) => row._id}
        paginationModel={{
          page: currentPage,
          pageSize: pageSize,
        }}
        getRowHeight={() => 'auto'}
      />
    </div>
  );
};
SearchResults.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      type: PropTypes.string,
      fields: PropTypes.shape({
        title: PropTypes.string,
        published_at: PropTypes.string,
      }),
      featuredIndex: PropTypes.number,
    })
  ),
  totalRowCount: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func.isRequired,
  setEditState: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  onPaginationChange: PropTypes.func,
};

const formatDailyScripture = (row) => {
  const book = get(row, 'book', '');
  const chapter = get(row, 'chapter', '');
  const startVerse = get(row, 'startVerse', '');
  const endVerse = get(row, 'endVerse', '');
  const passage = formatPassage({ book, chapter, startVerse, endVerse });
  return {
    _id: get(row, '_id', ''),
    activeAt: dayjs(get(row, 'activeAt', '')).format('MM/DD/YYYY'),
    passage,
    prompt: get(row, 'prompt', ''),
    level: get(row, 'level', {}),
    guide: get(row, 'guide', {}),
    introduction: get(row, 'introduction', {}),
  };
};

const DailyScriptureTable = forwardRef(
  ({ setLastDate, handleAction, setEditState }, ref) => {
    useImperativeHandle(ref, () => ({
      refreshData: fetchData,
    }));

    const [dailyScriptures, setDailyScriptures] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [paginationChanged, setPaginationChanged] = useState(false);
    // eslint-disable-next-line no-magic-numbers
    const [pageSize, setPageSize] = useState(5);

    const fetchData = useCallback(async () => {
      restService
        .callEndpoint({
          endpoint: 'getScriptureList',
          body: {
            date: dayjs().utc().startOf('day'),
            pageSize,
            page: currentPage,
          },
          method: 'post',
        })
        .then((data) => {
          const dailyScripturesResult = get(data, 'scriptures', []).map((s) =>
            formatDailyScripture(s)
          );
          setDailyScriptures(dailyScripturesResult);
          setLastDate(get(data, 'latestScripture.activeAt'));
          setTotalRowCount(get(data, 'totalCount', 0));
          setPaginationChanged(false);
        });
    }, [currentPage, pageSize, setLastDate]);

    useEffect(() => {
      if (paginationChanged) {
        fetchData();
      }
    }, [fetchData, paginationChanged]);

    useEffect(() => {
      fetchData();
      // eslint-disable-next-line
    }, []);

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {!isEmpty(dailyScriptures) && (
          <SearchResults
            rows={dailyScriptures}
            totalRowCount={totalRowCount}
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            handleAction={handleAction}
            setEditState={setEditState}
            onPaginationChange={() => setPaginationChanged(true)}
          />
        )}
      </Box>
    );
  }
);

DailyScriptureTable.propTypes = {
  setLastDate: PropTypes.func,
  handleAction: PropTypes.func.isRequired,
  setEditState: PropTypes.func.isRequired,
};

export default DailyScriptureTable;
