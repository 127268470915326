import { AppBar, Paper, Typography } from '@mui/material';

const AppHeader = () => (
  <AppBar position="static" sx={{ zIndex: 2 }}>
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        position: 'relative',
        borderRadius: 0,
        elevation: 4,
      }}
      elevation={0}
    >
      <Typography variant="h3" align="center" padding="1rem">
        Crossroads Anywhere Dashboard
      </Typography>
    </Paper>
  </AppBar>
);

export default AppHeader;
