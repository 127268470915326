import { useRef, useState } from 'react';

import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Modal,
} from '@mui/material/index';
import { isEmpty } from 'lodash';

import restService from 'services/restService';

import CreateLivestream from './CreateLivestream';
import LivestreamTable from './LivestreamTable';

const defaultState = {
  modalOpen: false,
  livestreamId: null,
  action: null,
};

const Livestream = () => {
  const [showCreate, setShowCreate] = useState(false);
  const [state, setState] = useState(defaultState);
  const tableRef = useRef(null);

  const handleModalClose = () => {
    setState(defaultState);
    tableRef.current.refreshData();
  };

  const handleUpdateLivestream = ({ action, id }) => {
    if (isEmpty(action) || isEmpty(id)) {
      return;
    }
    if (action === 'delete') {
      restService
        .callEndpoint({
          endpoint: 'deleteLivestream',
          body: { action, id },
          method: 'post',
        })
        .then(() => handleModalClose());
    }
  };

  return (
    <div>
      <Modal open={state.modalOpen}>
        <Box>
          <Dialog
            open={state.modalOpen}
            onClose={handleModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Update Livestream'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to {state.action} this livestream?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleModalClose}>Go Back</Button>
              <Button
                onClick={() => {
                  handleUpdateLivestream({
                    action: state.action,
                    id: state.id,
                  });
                }}
                autoFocus
              >
                {state.action} Livestream
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Modal>
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          margin: '0 0 2rem 0',
          alignItems: 'center',
        }}
      >
        <h1>Livestream Schedule</h1>
        <IconButton onClick={null} title="Refresh">
          <RefreshRoundedIcon color="primary" fontSize="large" />
        </IconButton>
        {showCreate ? (
          <Button variant="contained" onClick={() => setShowCreate(false)}>
            Show List
          </Button>
        ) : (
          <Button variant="contained" onClick={() => setShowCreate(true)}>
            Create
          </Button>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {showCreate ? (
          <CreateLivestream setShowCreate={setShowCreate} />
        ) : (
          <LivestreamTable setState={setState} ref={tableRef} />
        )}
      </Box>
    </div>
  );
};
export default Livestream;
