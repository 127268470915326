/* eslint react/prop-types: 0 */
import { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import restService from 'services/restService';

import ScriptureModalForm from './ScriptureModalForm';

const DEFAULT_PROMPT = 'What stood out to you?';

export default function EditDailyScriptureModal({ handleClose, lastDate, scriptureId }) {
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [values, setValues] = useState({});
  const [existingScripture, setExistingScripture] = useState({});
  const [error, setError] = useState();

  // eslint-disable-next-line complexity
  const handleSubmit = async (remove = false) => {
    if (isEmpty(values.book) || isEmpty(values.chapter)) {
      setError('Book and Chapter are required.');
      return;
    } else {
      setError('');
    }

    const scripture = await restService
      .callEndpoint({
        endpoint: 'getDailyScriptureByDate',
        body: { activeAt: values.activeAt },
        method: 'post',
      })
      .catch((error) => setError(error.message));

    if (!isEmpty(scripture._id) && scripture._id !== values._id && !remove) {
      setExistingScripture(scripture);
      setSaveModalOpen(true);
      return;
    }

    let result = {};

    if (remove) {
      result = await restService
        .callEndpoint({
          endpoint: 'deleteDailyScripture',
          body: { scriptureId: values._id },
          method: 'post',
        })
        .then((result) => {
          if (result) {
            setError('');
          }
        })
        .catch((error) => {
          return { error: error?.response?.data?.message ?? error.message };
        });
    } else {
      result = await restService
        .callEndpoint({
          endpoint: 'editDailyScripture',
          body: {
            ...values,
            ...(!isEmpty(values.content?.level?._id) && {
              content: { level: { _id: values.content.level._id } },
            }),
          },
          method: 'post',
        })
        .then((result) => {
          if (result) {
            setError('');
          }
        })
        .catch((error) => {
          return { error: error?.response?.data?.message ?? error.message };
        });
    }

    if (result?.error) {
      setError(result.error);
      return;
    }

    setExistingScripture({});

    return handleClose();
  };

  useEffect(() => {
    if (!isEmpty(scriptureId)) {
      restService
        .callEndpoint({
          endpoint: 'getDailyScriptureById',
          params: { id: scriptureId },
          method: 'get',
        })
        // eslint-disable-next-line complexity
        .then((data) => {
          const scripture = data?.data;
          setValues({
            _id: scripture?._id,
            activeAt: dayjs(scripture?.activeAt),
            book: scripture?.book,
            chapter: scripture?.chapter,
            startVerse: Number(scripture?.startVerse),
            endVerse: Number(scripture?.endVerse),
            prompt: scripture?.prompt || DEFAULT_PROMPT,
            content: { guide: scripture?.guide, level: scripture?.level },
            introduction: scripture?.introduction,
          });
        })
        .catch((error) => setError(error.message));
    }
  }, [scriptureId]);

  return (
    <div>
      <ScriptureModalForm
        error={error}
        existingScripture={existingScripture}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        lastDate={lastDate}
        overwriteCancelText="Edit"
        overwriteConfirmText="Delete"
        overwriteText="Would you like to go back to edit or delete it?"
        saveModalOpen={saveModalOpen}
        setError={setError}
        setExistingScripture={setExistingScripture}
        setSaveModalOpen={setSaveModalOpen}
        setValues={setValues}
        title="Edit Daily Scripture"
        values={values}
      />
    </div>
  );
}
