import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import restService from 'services/restService';

const Sidebar = ({ setIsAuthenticated, widgets }) => {
  const location = useLocation();

  const handleLogout = () => {
    restService.logout(setIsAuthenticated).catch((error) => {
      console.error(error); // eslint-disable-line no-console
    });
  };

  const getRouteLink = (widget) => {
    const route = `/widgets/${widget.routePath}`;
    return (
      <ListItem key={widget.label}>
        <ListItemButton
          to={route}
          selected={location.pathname === route}
          component={RouterLink}
        >
          {widget.label}
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          width: '20rem',
          position: 'relative',
          height: '100%',
        },
      }}
      PaperProps={{
        elevation: 1, // Set the elevation to 4
      }}
      variant="permanent"
      anchor="left"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
          padding: '1rem',
        }}
      >
        <Box>
          <Typography variant="h5">Choose a Widget</Typography>
          <List>
            {widgets.map((widget) =>
              isEmpty(widget?.children) ? (
                getRouteLink(widget)
              ) : (
                <ListItem key={widget.label} nested="true">
                  <List>
                    <ListSubheader
                      sx={{ backgroundColor: 'transparent', color: 'grey', fontSize: 16 }}
                    >
                      {widget.label}
                    </ListSubheader>
                    {widget.children?.map(getRouteLink)}
                  </List>
                </ListItem>
              )
            )}
          </List>
        </Box>
        <Button
          variant="contained"
          size="large"
          sx={{ width: '100%' }}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Box>
    </Drawer>
  );
};
Sidebar.propTypes = {
  setIsAuthenticated: PropTypes.func.isRequired,
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      routePath: PropTypes.string,
      Component: PropTypes.func,
      label: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          routePath: PropTypes.string.isRequired,
          Component: PropTypes.func.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ),
};

export default Sidebar;
