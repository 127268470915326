/* eslint-disable no-magic-numbers */
import { useCallback, useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, TextField } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { isEmpty } from 'lodash';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import DataTable from 'components/DataTable';
import Profile from 'components/Profile';
import restService from 'services/restService';

import ActionDialog from './ActionDialog';
import AddScriptureIntroductionModal from './AddScriptureIntroductionModal';
import EditScriptureIntroductionModal from './EditScriptureIntroductionModal';

const defaultSearch = {
  text: '',
  title: '',
};

const defaultActionState = {
  id: '',
  action: '',
  isOpen: false,
  title: '',
};

const ScriptureIntroductions = () => {
  const [introductions, setIntroductions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [paginationChanged, setPaginationChanged] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [search, setSearch] = useState(defaultSearch);
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [actionState, setActionState] = useState(defaultActionState);

  const columns = [
    {
      field: 'title',
      headerName: 'Title (Internal Use)',
      flex: 1,
      valueGetter: (value, row) => row?.title,
    },
    {
      field: 'displayTitle',
      headerName: 'Display Title',
      flex: 1,
      valueGetter: (value, row) => row?.displayTitle,
    },
    {
      field: 'text',
      headerName: 'Text',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ width: 200 }}>
          <Markdown rehypePlugins={[rehypeRaw]}>{params.row?.text}</Markdown>
        </Box>
      ),
    },
    {
      field: 'videoUrl',
      headerName: 'Video Url',
      flex: 1,
      valueGetter: (value, row) => row?.videoUrl,
    },
    {
      field: 'user',
      headerName: 'Introduction By',
      flex: 1,
      renderCell: (params) => {
        const user = params.row?.user?.profile;

        return !isEmpty(user) ? (
          <Profile
            firstName={user?.firstName}
            lastName={user?.lastName}
            profileImage={user?.image}
          />
        ) : null;
      },
    },
    {
      field: 'linkedScriptureCount',
      headerName: 'Linked Scripture Count',
      flex: 1,
      valueGetter: (value, row) => row?.linkedScriptures?.length || 0,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      valueGetter: (value, row) => row?.createdAt,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      cellClassName: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          title="Edit"
          key="Edit"
          onClick={() => handleEditClick({ id: params.id })}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          title="Delete"
          key="delete"
          onClick={() => handleDeleteClick({ id: params.id, title: params.row?.title })}
          color="inherit"
        />,
      ],
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  }));

  const handleDeleteClick = ({ id, title: introTitle }) =>
    setActionState({
      id,
      action: 'delete',
      confirmText: 'Delete Introduction',
      dialogText:
        'Are you sure you want to delete this introduction? This will also remove it from any linked daily scriptures.',
      isOpen: true,
      title: `Delete Scripture Introduction: ${introTitle}`,
    });

  const handleEditClick = ({ id }) => {
    setActionState({ id });
    setShowEdit(true);
  };

  const handleDeleteConfirm = () =>
    restService
      .callEndpoint({
        endpoint: 'deleteDailyScriptureIntroduction',
        body: { id: actionState.id },
        method: 'post',
      })
      .then((response) => {
        if (response) {
          setActionState(defaultActionState);
          fetchData(search);
        }
      });

  const handleSearch = () => fetchData(search);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const fetchData = useCallback(
    (searchQuery) => {
      let validSearchValues = Object.fromEntries(
        Object.entries(searchQuery).filter(([, v]) => v !== '')
      );
      restService
        .callEndpoint({
          endpoint: 'getDailyScriptureIntroductions',
          body: { pageSize, page: currentPage, query: validSearchValues },
          method: 'post',
        })
        .then((response) => {
          setIntroductions(response?.scriptureIntroductions ?? []);
          setTotalRowCount(response?.totalCount ?? 0);
          setPaginationChanged(false);
        });
    },
    [currentPage, pageSize]
  );

  useEffect(() => {
    if (paginationChanged) {
      fetchData(search);
    }
  }, [fetchData, paginationChanged, search]);

  useEffect(() => {
    fetchData(search);
  }, []); // eslint-disable-line

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '1rem',
          alignItems: 'center',
        }}
      >
        <h1>Daily Scripture Introductions</h1>
        <Button variant="contained" onClick={() => setShowCreate(true)}>
          Create
        </Button>
      </Box>
      <Box sx={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
        <TextField
          label="Title"
          variant="outlined"
          value={search?.title}
          onChange={(event) => setSearch({ ...search, title: event.target.value })}
          onKeyDown={handleKeyDown}
        />
        <TextField
          label="Text"
          variant="outlined"
          value={search?.text}
          onChange={(event) => setSearch({ ...search, text: event.target.value })}
          onKeyDown={handleKeyDown}
        />
        <TextField
          label="First Name"
          variant="outlined"
          value={search?.firstName}
          onChange={(event) => setSearch({ ...search, firstName: event.target.value })}
          onKeyDown={handleKeyDown}
        />
        <TextField
          label="Last Name"
          variant="outlined"
          value={search?.lastName}
          onChange={(event) => setSearch({ ...search, lastName: event.target.value })}
          onKeyDown={handleKeyDown}
        />
        <TextField
          label="Email"
          variant="outlined"
          value={search?.email}
          onChange={(event) => setSearch({ ...search, email: event.target.value })}
          onKeyDown={handleKeyDown}
        />
        <Button variant="contained" onClick={handleSearch}>
          Search
        </Button>
      </Box>
      <ActionDialog
        action={actionState.action}
        confirmText={actionState.confirmText}
        dialogText={actionState.dialogText}
        handleConfirm={handleDeleteConfirm}
        handleModalClose={() => setActionState(defaultActionState)}
        isOpen={actionState.isOpen}
        title={actionState.title}
      />
      <AddScriptureIntroductionModal
        handleClose={() => {
          setShowCreate(false);
          fetchData(defaultSearch);
        }}
        isOpen={showCreate}
      />
      <EditScriptureIntroductionModal
        handleClose={() => {
          setShowEdit(false);
          setActionState(defaultActionState);
          fetchData(defaultSearch);
        }}
        id={actionState.id}
        isOpen={showEdit}
      />
      <DataTable
        columns={columns}
        rows={introductions}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        onPaginationChange={() => setPaginationChanged(true)}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalRowCount={totalRowCount}
      />
    </div>
  );
};

export default ScriptureIntroductions;
