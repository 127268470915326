import { useEffect, useRef } from 'react';

import {
  MDXEditor,
  headingsPlugin,
  linkPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  quotePlugin,
  toolbarPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  ListsToggle,
} from '@mdxeditor/editor';
import { Alert, Box, Button, Modal, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { isEmpty, noop } from 'lodash';
import PropTypes from 'prop-types';

import SelectUser from './SelectUser';

import formatPassage from '../formatPassage';

const DEFAULT_DISPLAY_TITLE = 'Why are we reading this?';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  p: 4,
  overflowY: 'scroll',
};

// eslint-disable-next-line complexity
const ScriptureIntroductionForm = ({
  error,
  handleClose = noop,
  handleSubmit = noop,
  isOpen = false,
  setError,
  setValues,
  title,
  values,
}) => {
  const mdxEditorRef = useRef(null);

  useEffect(() => {
    if (!isEmpty(values?.text)) {
      mdxEditorRef.current?.setMarkdown(values.text);
    }
  }, [values?.text]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Button
          variant="contained"
          onClick={handleClose}
          style={{
            float: 'right',
            fontSize: 15,
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
        >
          X
        </Button>
        <Typography
          id="modal-modal-title"
          variant="h3"
          component="h2"
          sx={{ marginBottom: '20px', color: 'text.primary' }}
        >
          {title}
        </Typography>
        {error && (
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            margin: '0px 0 15px 15px',
            alignItems: 'flex-start',
          }}
        >
          <Typography variant="h5" sx={{ color: 'text.primary' }}>
            Introduction
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1rem',
            }}
          >
            <TextField
              value={values?.title || ''}
              label="Title (Internal Use)"
              required
              placeholder="Title"
              onChange={(event) => {
                setValues({ ...values, title: event.target.value });
                setError('');
              }}
              sx={{ minWidth: '70px' }}
            />
            <TextField
              value={values?.displayTitle}
              label="Display Title"
              placeholder="Display Title"
              onChange={(event) => {
                setValues({ ...values, displayTitle: event.target.value });
                setError('');
              }}
              sx={{ minWidth: '70px' }}
            />
            <TextField
              value={values?.videoUrl || ''}
              label="Video Url"
              placeholder="Video Url"
              onChange={(event) => {
                setValues({ ...values, videoUrl: event.target.value });
                setError('');
              }}
              sx={{ minWidth: '70px' }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography sx={{ color: 'text.primary' }}>Introduction Text</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <MDXEditor
                ref={mdxEditorRef}
                className="dark-theme dark-editor markdown-editor"
                contentEditableClassName="prose"
                placeholder={'Introduction text'}
                markdown={values?.text || ''}
                onChange={(update) => setValues({ ...values, text: update })}
                plugins={[
                  headingsPlugin(),
                  linkPlugin(),
                  listsPlugin(),
                  markdownShortcutPlugin(),
                  quotePlugin(),
                  toolbarPlugin({
                    toolbarClassName: 'markdown-toolbar',
                    toolbarContents: () => (
                      <>
                        <UndoRedo />
                        <BoldItalicUnderlineToggles />
                        <ListsToggle />
                      </>
                    ),
                  }),
                ]}
              />
              <Typography sx={{ color: 'gray', fontSize: 12, fontStyle: 'italic' }}>
                Markdown supported
              </Typography>
            </Box>
          </Box>
          <SelectUser
            user={values?.user}
            handleSelect={(user) => setValues({ ...values, user })}
          />
          {!isEmpty(values?.linkedScriptures) && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
              }}
            >
              <Typography variant="h5" sx={{ color: 'text.primary' }}>
                Linked Scriptures
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                {values?.linkedScriptures?.map((linked) => (
                  <Typography key={linked._id} variant="body1" color="text.primary">
                    {formatPassage(linked)}, Active At:{' '}
                    {dayjs(linked.activeAt).toString()}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            onClick={() => handleSubmit(false)}
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ScriptureIntroductionForm.propTypes = {
  error: PropTypes.string,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  setError: PropTypes.func,
  setValues: PropTypes.func,
  title: PropTypes.string,
  values: PropTypes.shape({
    displayTitle: PropTypes.string,
    introductionBy: PropTypes.string,
    linkedScriptures: PropTypes.arrayOf(
      PropTypes.shape({
        activeAt: PropTypes.string,
        book: PropTypes.string,
        chapter: PropTypes.string,
        startVerse: PropTypes.number,
        endVerse: PropTypes.number,
      })
    ),
    user: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    text: PropTypes.string,
    title: PropTypes.string,
    videoUrl: PropTypes.string,
  }),
};

export { ScriptureIntroductionForm as default, DEFAULT_DISPLAY_TITLE };
