import { useEffect, useRef, useState } from 'react';

import { EditExploreContent } from './ExploreContentEdit';
import ExploreTable from './ExploreContentTable';
import ExploreSearch from './ExploreSearch';

const defaultState = {
  selectedContent: null,
  editContentModal: false,
};

const ExploreHero = () => {
  const [{ selectedContent, showEditContentModal }, setState] = useState(defaultState);

  const exploreRef = useRef(null);
  const searchRef = useRef(null);

  useEffect(() => {
    if (
      exploreRef &&
      exploreRef.current &&
      searchRef &&
      searchRef.current &&
      !selectedContent
    ) {
      exploreRef.current.refreshData();
      searchRef.current.refreshData();
    }
  }, [selectedContent]);

  return (
    <div>
      <h1>Explore Hero Slider</h1>
      <ExploreTable setContentState={setState} ref={exploreRef} />
      <h1>Search For Content</h1>
      <ExploreSearch setContentState={setState} ref={searchRef} />
      {selectedContent && showEditContentModal && (
        <EditExploreContent
          content={selectedContent}
          clearSelectedContent={() => setState(defaultState)}
        />
      )}
    </div>
  );
};

export default ExploreHero;
