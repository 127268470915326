import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Link } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';

const displayGuideProgressMetric = (metric) => {
  switch (metric) {
    case 'section':
      return 'Heading';
    case 'level':
      return 'Level';
    default:
      return '';
  }
};

const SearchResults = ({
  rows,
  totalRowCount,
  currentPage = 0,
  setCurrentPage,
  pageSize,
  setPageSize,
  deleteContent,
  onPaginationChange = noop,
}) => {
  const columns = [
    {
      field: 'linkedId',
      headerName: 'Linked Content Id',
      flex: 1,
      valueGetter: (value, row) => get(row, 'linkedContent._id', ''),
    },
    {
      field: 'linkedType',
      headerName: 'Linked Content Type',
      flex: 1,
      valueGetter: (value, row) => get(row, 'linkedContent.type', ''),
    },
    {
      field: 'linkedTitle',
      headerName: 'Linked Content Title',
      flex: 2,
      valueGetter: (value, row) => get(row, 'linkedContent.title', ''),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      valueGetter: (value, row) => (row?.createdAt ? dayjs(row.createdAt) : ''),
    },
    {
      field: 'heading',
      headerName: 'Heading',
      flex: 1,
      valueGetter: (value, row) => get(row, 'heading', ''),
    },
    {
      field: 'subheading',
      headerName: 'Subheading',
      flex: 1,
      valueGetter: (value, row) => get(row, 'subheading', ''),
    },
    {
      field: 'buttonText',
      headerName: 'Button Text',
      flex: 1,
      valueGetter: (value, row) => get(row, 'buttonText', ''),
    },
    {
      field: 'progressMetric',
      headerName: 'Progress Metric',
      flex: 1,
      valueGetter: (value, row) => displayGuideProgressMetric(row?.guideProgress?.metric),
    },
    {
      field: 'progressUnit',
      headerName: 'Progress Unit',
      flex: 1,
      valueGetter: (value, row) => get(row, 'guideProgress.unit', ''),
    },
    {
      field: 'backgroundImage',
      headerName: 'Background Image',
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="text"
          onClick={() => window.open(params.row?.backgroundImage, '_blank')}
        >
          View Image
        </Button>
      ),
    },
    {
      field: 'url',
      headerName: 'URL',
      flex: 1,
      renderCell: (params) => <Link href={params.row?.url}>{params.row?.url}</Link>,
    },
    {
      field: 'startOn',
      headerName: 'Start On',
      flex: 1,
      valueGetter: (value, row) => (row?.startOn ? dayjs(get(row, 'startOn')) : ''),
    },
    {
      field: 'endOn',
      headerName: 'End On',
      flex: 1,
      valueGetter: (value, row) => (row?.endOn ? dayjs(get(row, 'endOn')) : ''),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          title="Delete"
          key={`delete-${id}`}
          onClick={() => deleteContent(id)}
          color="inherit"
        />,
      ],
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  }));

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.pageSize === pageSize ? paginationModel.page : 0);

    setPageSize(paginationModel.pageSize);
    onPaginationChange();
  };

  // eslint-disable-next-line no-magic-numbers
  const pageSizeOptions = [5, 10, 20, 30];

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={rows}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={pageSizeOptions}
        pagination
        disableColumnFilter
        disableRowSelectionOnClick
        paginationMode="server"
        rowCount={totalRowCount}
        getRowId={(row) => row._id}
        paginationModel={{
          page: currentPage, // DataGrid uses zero-based page numbering
          pageSize: pageSize,
        }}
      />
    </div>
  );
};
SearchResults.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      prayerText: PropTypes.string,
      createdAt: PropTypes.string,
      prayedForCount: PropTypes.number,
    })
  ),
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func.isRequired,
  totalRowCount: PropTypes.number,
  deleteContent: PropTypes.func.isRequired,
  onPaginationChange: PropTypes.func,
};

export default SearchResults;
