import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { get, isEmpty, noop } from 'lodash';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import restService from 'services/restService';

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const SearchBar = ({ onSearch, searchQuery, setSearchQuery }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
      <TextField
        label="Title"
        variant="outlined"
        sx={{ flexGrow: 5 }}
        value={searchQuery.title}
        onChange={(event) =>
          setSearchQuery({ ...searchQuery, title: event.target.value })
        }
        onKeyDown={handleKeyDown}
      />
      <Button variant="contained" sx={{ flexGrow: 1 }} onClick={onSearch}>
        Search
      </Button>
    </Box>
  );
};
SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchQuery: PropTypes.shape({
    title: PropTypes.string,
    contentTypes: PropTypes.arrayOf(PropTypes.string),
  }),
  setSearchQuery: PropTypes.func.isRequired,
};

const SearchResults = ({
  rows,
  totalRowCount,
  currentPage = 1,
  setCurrentPage,
  pageSize,
  setPageSize,
  setContent,
  closeModal,
  onPaginationChange = noop,
}) => {
  const selectContent = (content) => {
    setContent(content);
    closeModal();
  };

  const columns = [
    {
      field: 'levelId',
      headerName: 'Level Id',
      flex: 1,
      valueGetter: (value, row) => row?.level?._id,
    },
    {
      field: 'levelTitle',
      headerName: 'Level Title',
      flex: 2,
      valueGetter: (value, row) => row?.level?.title,
    },
    {
      field: 'position',
      headerName: 'Level Position',
      flex: 2,
      valueGetter: (value, row) => row?.level?.position + 1 || 1,
    },
    {
      field: 'guideTitle',
      headerName: 'Guide Title',
      flex: 2,
      valueGetter: (value, row) => row?.guide?.fields?.title,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      valueGetter: (value, row) => row?.level?.createdAt,
    },
    {
      field: 'select',
      headerName: '',
      flex: 1,
      renderCell: (params) => (
        <Button onClick={() => selectContent(params.row)} variant="contained">
          Select
        </Button>
      ),
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  }));

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.pageSize === pageSize ? paginationModel.page : 0);

    setPageSize(paginationModel.pageSize);
    onPaginationChange();
  };

  // eslint-disable-next-line no-magic-numbers
  const pageSizeOptions = [5, 10, 20, 30];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={rows}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={pageSizeOptions}
        pagination
        disableColumnFilter
        disableRowSelectionOnClick
        paginationMode="server"
        rowCount={totalRowCount}
        getRowId={() => nanoid()}
        paginationModel={{
          page: currentPage,
          pageSize: pageSize,
        }}
      />
    </div>
  );
};
SearchResults.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.shape({
        _id: PropTypes.string,
        title: PropTypes.string,
        createdAt: PropTypes.string,
      }),
      guide: PropTypes.shape({
        _id: PropTypes.string,
        type: PropTypes.string,
        fields: PropTypes.shape({
          title: PropTypes.string,
          published_at: PropTypes.string,
        }),
      }),
    })
  ),
  totalRowCount: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func.isRequired,
  setContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onPaginationChange: PropTypes.func,
};

const SearchForContent = ({ setContent, closeModal }) => {
  const [searchQuery, setSearchQuery] = useState({
    title: '',
    contentTypes: ['guide'],
  });

  const [contentFound, setContentFound] = useState([]);
  // eslint-disable-next-line no-magic-numbers
  const [pageSize, setPageSize] = useState(5);
  const prevPageSize = usePrevious(pageSize);
  const [currentPage, setCurrentPage] = useState(0);
  const prevCurrentPage = usePrevious(currentPage);
  const [paginationChanged, setPaginationChanged] = useState(false);
  const [totalRowCount, setTotalRowCount] = useState(0);

  const fetchData = useCallback(async ({ page, size, query }) => {
    let validSearchValues = Object.fromEntries(
      Object.entries(query).filter(([, v]) => v !== '')
    );
    if (Object.keys(validSearchValues).length) {
      const body = { query: validSearchValues, page, pageSize: size };
      restService
        .callEndpoint({ endpoint: 'searchContent', body, method: 'post' })
        .then((data) => {
          const mappedLevels = get(data, 'content', [])
            .filter((guide) => !isEmpty(guide?.levels))
            .flatMap((guide) =>
              guide?.levels?.map((level) => ({
                level,
                guide,
              }))
            );
          setContentFound(mappedLevels);
          setTotalRowCount(get(data, 'totalCount', 0));
          setPaginationChanged(false);
        });
    }
  }, []);

  const handleSearch = () => {
    fetchData({ page: currentPage, size: pageSize, query: searchQuery });
  };

  useEffect(() => {
    if (
      (prevPageSize && pageSize && prevPageSize !== pageSize) ||
      (prevCurrentPage && currentPage && prevCurrentPage !== currentPage)
    ) {
      fetchData({ page: currentPage, size: pageSize, query: searchQuery });
    }
  }, [currentPage, fetchData, pageSize, prevCurrentPage, prevPageSize, searchQuery]);

  useEffect(() => {
    if (paginationChanged) {
      fetchData({ page: currentPage, size: pageSize, query: searchQuery });
    }
  }, [currentPage, fetchData, pageSize, paginationChanged, searchQuery]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <SearchBar
        onSearch={handleSearch}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      {!isEmpty(contentFound) && (
        <SearchResults
          rows={contentFound}
          totalRowCount={totalRowCount}
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setContent={setContent}
          closeModal={closeModal}
          onPaginationChange={() => setPaginationChanged(true)}
        />
      )}
    </Box>
  );
};

SearchForContent.propTypes = {
  setContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const SearchModal = ({ open, handleModalClose, setContent }) => {
  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ backdropFilter: 'blur(15px)' }}
    >
      <Box
        sx={{
          position: 'absolute',
          flex: 1,
          padding: '2rem',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          height: '70%',
          bgcolor: 'background.paper',
          borderRadius: '2rem',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: 'text.primary',
            marginBottom: '1rem',
            marginTop: '1rem',
          }}
        >
          Search for Guide
        </Typography>
        <SearchForContent setContent={setContent} closeModal={handleModalClose} />
      </Box>
    </Modal>
  );
};

SearchModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  setContent: PropTypes.func.isRequired,
};

export default SearchModal;
