import { useCallback, useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Box, Alert } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { get, isEmpty, noop } from 'lodash';
import PropTypes from 'prop-types';

import restService from 'services/restService';

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const Table = ({
  rows,
  totalRowCount,
  currentPage = 0,
  setCurrentPage,
  pageSize,
  setPageSize,
  setContentState,
  onPaginationChange = noop,
}) => {
  const columns = [
    {
      field: 'contentId',
      headerName: 'Id',
      flex: 1,
      valueGetter: (value, row) => row._id,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 2,
      valueGetter: (value, row) => row.fields?.title,
    },
    {
      field: 'type',
      headerName: 'Content Type',
      flex: 2,
      valueGetter: (value, row) => capitalize(row.type),
    },
    {
      field: 'publishedAt',
      headerName: 'Published At',
      flex: 2,
      valueGetter: (value, row) => row.fields?.published_at,
    },
    {
      field: 'exploreIndex',
      headerName: 'Explore Index',
      flex: 2,
      valueGetter: (value, row) => row.exploreIndex,
    },
    {
      field: 'Edit Content',
      headerName: 'Edit Content',
      flex: 1,
      renderCell: (params) => (
        <>
          <EditIcon
            style={{ cursor: 'pointer' }}
            onClick={() =>
              setContentState({
                selectedContent: params.row,
                showEditContentModal: true,
              })
            }
          />
        </>
      ),
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  }));

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.pageSize === pageSize ? paginationModel.page : 0);

    setPageSize(paginationModel.pageSize);
    onPaginationChange();
  };

  // eslint-disable-next-line no-magic-numbers
  const pageSizeOptions = [5, 10, 20, 30];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        rowCount={totalRowCount}
        getRowId={(row) => row._id}
        pageSizeOptions={pageSizeOptions}
        pagination
        paginationMode="server"
        paginationModel={{
          page: currentPage,
          pageSize,
        }}
        onPaginationModelChange={handlePaginationModelChange}
        disableColumnFilter
        disableRowSelectionOnClick
      />
    </div>
  );
};
Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      type: PropTypes.string,
      fields: PropTypes.shape({
        title: PropTypes.string,
        published_at: PropTypes.string,
      }),
      exploreIndex: PropTypes.number,
    })
  ),
  totalRowCount: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func.isRequired,
  setContentState: PropTypes.func.isRequired,
  onPaginationChange: PropTypes.func,
};

const ExploreTable = forwardRef(({ setContentState }, ref) => {
  useImperativeHandle(ref, () => ({
    refreshData: () => handleSearch(),
  }));

  const [content, setContent] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationChanged, setPaginationChanged] = useState(false);
  // eslint-disable-next-line no-magic-numbers
  const [pageSize, setPageSize] = useState(5);

  const fetchData = useCallback(
    () =>
      restService
        .callEndpoint({
          endpoint: 'getExploreContent',
          params: { pageSize, page: currentPage },
          method: 'get',
        })
        .then((res) => {
          setContent(get(res, 'data.content', []));
          setTotalRowCount(get(res, 'data.totalCount', 0));
          setPaginationChanged(false);
        }),
    [currentPage, pageSize]
  );

  const handleSearch = () => {
    fetchData();
  };

  useEffect(() => {
    if (paginationChanged) {
      fetchData();
    }
  }, [fetchData, paginationChanged]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      {isEmpty(content) && (
        <Alert variant="filled" severity="info">
          No explore content set. Search for content below to select content to be
          featured in the explore hero.
        </Alert>
      )}
      {!isEmpty(content) && (
        <Table
          rows={content}
          totalRowCount={totalRowCount}
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setContentState={setContentState}
          onPaginationChange={() => setPaginationChanged(true)}
        />
      )}
    </Box>
  );
});

ExploreTable.propTypes = {
  setContentState: PropTypes.func.isRequired,
};

export default ExploreTable;
