import { useCallback, useEffect, useState } from 'react';

import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { get } from 'lodash';

import restService from 'services/restService';

import CreateSpotlight from './CreateSpotlight';
import SpotlightSearchResults from './SpotlightSearchResults';

const Spotlight = () => {
  const [spotlightFound, setSpotlightFound] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [showCreate, setShowCreate] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationChanged, setPaginationChanged] = useState(false);
  // eslint-disable-next-line no-magic-numbers
  const [pageSize, setPageSize] = useState(5);

  const fetchData = useCallback(async () => {
    restService
      .callEndpoint({
        endpoint: 'getSpotlight',
        params: { pageSize, page: currentPage },
        method: 'get',
      })
      .then((data) => {
        setSpotlightFound(get(data, 'data.spotlights', []) || []);
        setTotalRowCount(get(data, 'data.totalCount', 0));
        setPaginationChanged(false);
      });
  }, [currentPage, pageSize]);

  const handleDeleteSpotlight = async (id) => {
    restService
      .callEndpoint({
        endpoint: 'updateSpotlight',
        body: {
          _id: id,
          update: {
            delete: true,
          },
        },
        method: 'post',
      })
      .then(() => {
        fetchData();
      });
  };

  useEffect(() => {
    if (paginationChanged) {
      fetchData();
    }
  }, [fetchData, paginationChanged]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          gap: '1rem',
          marginBottom: '2rem',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2">Home Tab Spotlight</Typography>
        {showCreate ? (
          <Button variant="contained" onClick={() => setShowCreate(false)}>
            Show List
          </Button>
        ) : (
          <>
            <IconButton onClick={fetchData}>
              <RefreshRoundedIcon color="primary" />
            </IconButton>
            <Button variant="contained" onClick={() => setShowCreate(true)}>
              Create
            </Button>
          </>
        )}
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {showCreate ? (
          <CreateSpotlight
            refetchData={fetchData}
            onComplete={() => setShowCreate(false)}
          />
        ) : (
          <SpotlightSearchResults
            totalRowCount={totalRowCount}
            rows={spotlightFound}
            deleteContent={handleDeleteSpotlight}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            onPaginationChange={() => setPaginationChanged(true)}
          />
        )}
      </Box>
    </>
  );
};

export default Spotlight;
