import { useState, useRef } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
} from '@mui/material';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import restService from 'services/restService';

import AddDailyScriptureButton from './AddDailyScriptureButton';
import AddDailyScriptureModal from './AddDailyScriptureModal';
import DailyScriptureTable from './DailyScriptureTable';
import EditDailyScriptureModal from './EditDailyScriptureModal';

const defaultState = {
  modalOpen: false,
  scriptureId: null,
  action: null,
};

const defaultEditState = {
  modalOpen: false,
  scripture: null,
};

const DailyScripture = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [lastDate, setLastDate] = useState(dayjs());
  const tableRef = useRef(null);
  const [state, setState] = useState(defaultState);
  const [editState, setEditState] = useState(defaultEditState);

  const handleCloseAddScriptureModal = () => {
    setShowAddModal(false);
    tableRef.current.refreshData();
  };

  const handleCloseEditScriptureModal = () => {
    setEditState(defaultEditState);
    tableRef.current.refreshData();
  };

  const handleAction = ({ modalOpen, scriptureId, action }) =>
    setState({ modalOpen, scriptureId, action });

  const handleModalClose = () => {
    setState(defaultState);
    tableRef.current.refreshData();
  };

  const handleUpdateScripture = ({ action, scriptureId }) => {
    if (isEmpty(action) || isEmpty(scriptureId)) {
      return;
    }

    if (action === 'delete') {
      restService
        .callEndpoint({
          endpoint: 'deleteDailyScripture',
          body: { scriptureId },
          method: 'post',
        })
        .then(() => handleModalClose());
    }

    if (action === 'update') {
      restService
        .callEndpoint({
          endpoint: 'updateDailyScripture',
          body: { scriptureId },
          method: 'post',
        })
        .then(() => handleCloseEditScriptureModal());
    }
  };

  return (
    <>
      <Modal open={state.modalOpen}>
        <Box>
          <Dialog
            open={state.modalOpen}
            onClose={handleModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Update Scripture'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to {state.action} this scripture?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleModalClose}>Go Back</Button>
              <Button
                onClick={() => {
                  handleUpdateScripture({
                    action: state.action,
                    scriptureId: state.scriptureId,
                  });
                }}
                autoFocus
              >
                {state.action} Scripture
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Modal>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          justifyContent: 'center',
        }}
      >
        <h1>Daily Scripture Readings</h1>
        <AddDailyScriptureButton onClick={() => setShowAddModal(true)} />
        <DailyScriptureTable
          ref={tableRef}
          setLastDate={setLastDate}
          handleAction={handleAction}
          setEditState={setEditState}
        />
        {showAddModal && (
          <AddDailyScriptureModal
            handleClose={handleCloseAddScriptureModal}
            lastDate={lastDate}
          />
        )}
        {editState.modalOpen && (
          <EditDailyScriptureModal
            handleClose={handleCloseEditScriptureModal}
            lastDate={lastDate}
            scripture={editState.scripture}
            scriptureId={editState.scripture._id}
          />
        )}
      </Box>
    </>
  );
};

export default DailyScripture;
