import { Avatar, Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

const Profile = ({ firstName = '', lastName = '', profileImage = '' }: Props) => {
  const initials = `${firstName?.at(0)}${lastName?.at(0)}`;
  const name = `${firstName || ''} ${lastName || ''}`.trim();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        margin: '5px',
        alignItems: 'center',
      }}
    >
      <Avatar
        alt={name}
        src={profileImage}
        sx={{ width: 30, height: 30, marginRight: '5px' }}
      >
        {initials}
      </Avatar>
      {!isEmpty(name) && <Typography sx={{ color: 'text.primary' }}>{name}</Typography>}
    </Box>
  );
};

interface Props {
  firstName: string;
  lastName: string;
  profileImage: string;
}

export default Profile;
