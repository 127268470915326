import { useState } from 'react';

import EditUser from './EditUser';
import SearchForUsers from './SearchForUsers';
import ViewJournals from './ViewJournals';

const defaultState = {
  selectedUser: null,
  editUserModal: false,
  ViewJournals: false,
};

const UserSearch = () => {
  const [{ selectedUser, showEditUserModal, showViewJournalsModal }, setState] = useState(
    {
      selectedUser: null,
      showEditUserModal: false,
      showViewJournalsModal: false,
    }
  );

  return (
    <div>
      <h2>User Search</h2>
      <SearchForUsers setUserState={setState} />
      {selectedUser && showEditUserModal && (
        <EditUser user={selectedUser} clearSelectedUser={() => setState(defaultState)} />
      )}
      {selectedUser && showViewJournalsModal && (
        <ViewJournals
          user={selectedUser}
          clearSelectedUser={() => setState(defaultState)}
        />
      )}
    </div>
  );
};

export default UserSearch;
