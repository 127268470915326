import { useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import ContentSearchModal from './ContentSearchModal';

// eslint-disable-next-line complexity
export default function SelectContent({
  content,
  setContent,
  required = false,
  description = '',
  disabled = false,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => setModalOpen(false);

  return (
    <>
      <ContentSearchModal
        open={modalOpen}
        handleModalClose={handleModalClose}
        setContent={setContent}
      />
      <Typography
        variant="h5"
        sx={{ marginBottom: '1rem', color: 'text.primary' }}
      >{`Linked Guide Level${required ? '*' : ''}`}</Typography>
      <Typography variant="body1">{description}</Typography>
      <SearchResults rows={!isEmpty(content?.level) ? [content] : []} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          paddingTop: '10px',
        }}
      >
        <Button
          disabled={disabled}
          onClick={() => setModalOpen(true)}
          variant="contained"
          sx={{ marginRight: '1rem' }}
        >
          {!isEmpty(content?.level?._id) ? 'Change Content' : 'Select Content'}
        </Button>
        {!isEmpty(content?.level?._id) && (
          <Button disabled={disabled} onClick={() => setContent({})} variant="contained">
            Clear
          </Button>
        )}
      </Box>
    </>
  );
}
SelectContent.propTypes = {
  content: PropTypes.shape({
    level: PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
      createdAt: PropTypes.string,
    }),
    guide: PropTypes.shape({
      title: PropTypes.string,
      fields: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  }),
  setContent: PropTypes.func.isRequired,
  required: PropTypes.bool,
  description: PropTypes.string,
  disabled: PropTypes.bool,
};

const SearchResults = ({ rows, totalRowCount }) => {
  const columns = [
    {
      field: '_id',
      headerName: 'Id',
      flex: 1,
      valueGetter: (value, row) => row?.level?._id,
    },
    {
      field: 'levelTitle',
      headerName: 'Level',
      flex: 3,
      valueGetter: (value, row) => row?.level?.title,
    },
    {
      field: 'guideTitle',
      headerName: 'Guide Title',
      flex: 3,
      valueGetter: (value, row) => row?.guide?.fields?.title || row?.guide?.title,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      valueGetter: (value, row) =>
        row?.level?.createdAt ? dayjs(row.level.createdAt) : '',
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  }));

  const renderEmpty = () => (
    <Box
      sx={{
        padding: '2rem',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h6">No Content Selected</Typography>
    </Box>
  );

  return (
    <Box sx={{ flex: 1 }}>
      <DataGrid
        columns={columns}
        autoHeight
        rows={rows}
        disableColumnMenu
        hideFooter
        disableRowSelectionOnClick
        rowCount={totalRowCount}
        getRowId={() => nanoid()}
        slots={{
          noRowsOverlay: renderEmpty,
        }}
      />
    </Box>
  );
};
SearchResults.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      createdAt: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  totalRowCount: PropTypes.number,
};
