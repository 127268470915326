import { useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { isEmpty, noop } from 'lodash';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import DataTable from 'components/DataTable';
import Profile from 'components/Profile';

import ScriptureIntroSearchModal from './ScriptureIntroSearchModal';

import AddScriptureIntroductionModal from '../Introductions/AddScriptureIntroductionModal';

const columns = [
  {
    field: 'title',
    headerName: 'Title (Internal Use)',
    flex: 1,
    valueGetter: (value, row) => row?.title,
  },
  {
    field: 'text',
    headerName: 'Text',
    flex: 1,
    renderCell: (params) => (
      <Box sx={{ width: 200 }}>
        <Markdown rehypePlugins={[rehypeRaw]}>{params.row?.text}</Markdown>
      </Box>
    ),
  },
  {
    field: 'videoUrl',
    headerName: 'Video Url',
    flex: 1,
    valueGetter: (value, row) => row?.videoUrl,
  },
  {
    field: 'user',
    headerName: 'Introduction By',
    flex: 1,
    renderCell: (params) => {
      const user = params.row?.user?.profile;

      return !isEmpty(user) ? (
        <Profile
          firstName={user?.firstName}
          lastName={user?.lastName}
          profileImage={user?.image}
        />
      ) : null;
    },
  },
  {
    field: 'linkedScriptureCount',
    headerName: 'Linked Scripture Count',
    flex: 1,
    valueGetter: (value, row) => row?.linkedScriptures?.length || 0,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    flex: 1,
    valueGetter: (value, row) => row?.createdAt,
  },
].map((column) => ({
  ...column,
  sortable: false,
  headerAlign: 'center',
  align: 'center',
}));
export default function ScriptureIntro({
  introduction,
  disabled = false,
  handleChange = noop,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [introCreateOpen, setIntroCreateOpen] = useState(false);

  return (
    <>
      <ScriptureIntroSearchModal
        isOpen={modalOpen}
        handleModalClose={() => setModalOpen(false)}
        handleSelect={(update) => {
          setModalOpen(false);
          handleChange(update);
        }}
      />
      <AddScriptureIntroductionModal
        handleClose={() => {
          setIntroCreateOpen(false);
        }}
        isOpen={introCreateOpen}
        onSubmit={(update) => handleChange(update)}
      />
      <Typography variant="h5" sx={{ color: 'text.primary', marginBottom: '15px' }}>
        Scripture Introduction
      </Typography>
      {isEmpty(introduction?._id) ? (
        <>
          <Typography sx={{ color: 'text.primary', marginBottom: '15px' }}>
            No introduction selected
          </Typography>
          <Box sx={{ paddingTop: '10px' }}>
            <Button
              disabled={disabled}
              onClick={() => setModalOpen(true)}
              variant="contained"
            >
              Select Introduction
            </Button>
            <Button
              disabled={disabled}
              onClick={() => setIntroCreateOpen(true)}
              variant="contained"
              sx={{ marginLeft: '1rem' }}
            >
              Create Introduction
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ flex: 1 }}>
            <DataTable columns={columns} rows={[introduction]} hideFooter />
          </Box>
          <Box sx={{ paddingTop: '10px', display: 'flex' }}>
            <Button
              disabled={disabled}
              onClick={() => setModalOpen(true)}
              variant="contained"
            >
              Change Introduction
            </Button>
            <Button
              disabled={disabled}
              onClick={() => handleChange({})}
              variant="contained"
              sx={{ marginLeft: '1rem' }}
            >
              Clear
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

ScriptureIntro.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  introduction: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    videoUrl: PropTypes.string,
  }),
};
