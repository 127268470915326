import { useEffect, useState } from 'react';

import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { isEmpty, isNumber } from 'lodash';
import PropTypes from 'prop-types';

import restService from 'services/restService';

const Spacer = () => <div style={{ height: '1rem' }} />;

const getTimeString = (date) => `${date.hour()}:${date.minute()}`;

const CreateLivestream = ({ setShowCreate }) => {
  const [day, setDay] = useState('');
  const [startOn, setStartOn] = useState(dayjs());
  const [endOn, setEndOn] = useState(dayjs());
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const [isDefaultLoaded, setIsDefaultLoaded] = useState(false);

  const validateRequest = () => {
    if (!isNumber(day)) {
      throw new Error('Day is required.');
    }

    if (!startOn) {
      throw new Error('Start On is required.');
    }

    if (!endOn) {
      throw new Error('End On is required.');
    }

    if (isEmpty(url)) {
      throw new Error('Url is required.');
    }

    if (startOn === endOn) {
      throw new Error('End On must be after Start On');
    }

    if (startOn > endOn) {
      throw new Error('Start On must be before End On');
    }
  };

  const getDefaultUrl = () =>
    restService
      .callEndpoint({
        endpoint: 'getDefaultLivestreamUrl',
        method: 'get',
      })
      .then((result) => {
        setError('');
        setUrl(result?.data?.url);
        setIsDefaultLoaded(true);
      })
      .catch((error) => {
        setError(error.message);
      });

  const handleCreateLivestream = () => {
    try {
      validateRequest();
    } catch (error) {
      setError(error.message);
      return;
    }

    restService
      .callEndpoint({
        endpoint: 'createLivestream',
        body: {
          day,
          startOn: getTimeString(startOn),
          endOn: getTimeString(endOn),
          url,
        },
        method: 'post',
      })
      .then(() => {
        setError('');
        setShowCreate(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  useEffect(() => {
    if (!isDefaultLoaded) {
      getDefaultUrl();
    }
  }, [isDefaultLoaded]);

  return (
    <div>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {error && (
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        )}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 2,
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="day">Day</InputLabel>
            <Select
              labelId="day"
              value={day}
              label="Day"
              onChange={(event) => {
                setError('');
                setDay(event.target.value);
              }}
            >
              <MenuItem value={0}>Sunday</MenuItem>
              <MenuItem value={1}>Monday</MenuItem>
              <MenuItem value={2}>Tuesday</MenuItem>
              <MenuItem value={3}>Wednesday</MenuItem>
              <MenuItem value={4}>Thursday</MenuItem>
              <MenuItem value={5}>Friday</MenuItem>
              <MenuItem value={6}>Saturday</MenuItem>
            </Select>
          </FormControl>

          <TimePicker
            label="Start On"
            value={startOn}
            ampm={false}
            isRequired={true}
            onChange={(event) => {
              const time = dayjs(event);
              setError('');
              setStartOn(time);
            }}
          />
          <TimePicker
            label="End On"
            value={endOn}
            ampm={false}
            isRequired={true}
            onChange={(event) => {
              const time = dayjs(event);
              setError('');
              setEndOn(time);
            }}
          />
          <TextField
            value={url}
            label="Url"
            onChange={(event) => {
              setError('');
              setUrl(event?.target.value);
            }}
            required
          />
        </Box>
      </Box>
      <Spacer />
      <Button
        variant="contained"
        size="large"
        sx={{ width: '100%' }}
        onClick={handleCreateLivestream}
        disabled={!isEmpty(error)}
      >
        Create Livestream
      </Button>
    </div>
  );
};

CreateLivestream.propTypes = {
  setShowCreate: PropTypes.func.isRequired,
};

export default CreateLivestream;
