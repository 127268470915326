import { useCallback, useEffect, useState } from 'react';

import { isEmpty, omit } from 'lodash';
import PropTypes from 'prop-types';

import restService from 'services/restService';

import ScriptureIntroductionForm, {
  DEFAULT_DISPLAY_TITLE,
} from './ScriptureIntroductionForm';

const DEFAULT_VALUES = { displayTitle: DEFAULT_DISPLAY_TITLE };

const EditScriptureIntroductionModal = ({ id, handleClose, isOpen = false }) => {
  const [values, setValues] = useState(DEFAULT_VALUES);
  const [error, setError] = useState();

  const fetchIntroduction = useCallback(
    async (id) =>
      restService
        .callEndpoint({
          endpoint: 'getDailyScriptureIntroductionById',
          body: {
            id,
          },
          method: 'post',
        })
        .then((result) => {
          if (result) {
            const introduction = result?.at(0);
            setValues({
              ...values,
              ...introduction,
              user: {
                _id: introduction.user?._id,
                firstName: introduction.user?.profile?.firstName,
                lastName: introduction.user?.profile?.lastName,
                image: introduction.user?.profile?.image,
              },
            });
          }
        })
        .catch((error) => setError(error?.response?.data?.message ?? error.message)),
    // eslint-disable-next-line
    [id]
  );

  const handleSubmit = async () => {
    if (isEmpty(values?.text)) {
      setError('Text is required.');
      return;
    }

    if (isEmpty(values?.title)) {
      setError('Title is required.');
      return;
    }

    await restService
      .callEndpoint({
        endpoint: 'updateDailyScriptureIntroduction',
        body: {
          ...omit(values, ['user', 'linkedScriptures']),
          userId: values.user?._id,
        },
        method: 'post',
      })
      .then((result) => {
        if (result) {
          setError('');
          setValues(DEFAULT_VALUES);
          handleClose();
        }
      })
      .catch((error) => setError(error?.response?.data?.message ?? error.message));
  };

  useEffect(() => {
    if (!isEmpty(id)) {
      fetchIntroduction(id);
    }
  }, [fetchIntroduction, id]);

  return (
    <div>
      <ScriptureIntroductionForm
        error={error}
        handleClose={() => {
          handleClose();
          setValues(DEFAULT_VALUES);
        }}
        handleSubmit={handleSubmit}
        isOpen={isOpen}
        setError={setError}
        setValues={setValues}
        title="Update Daily Scripture Introduction"
        values={values}
      />
    </div>
  );
};

EditScriptureIntroductionModal.propTypes = {
  id: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default EditScriptureIntroductionModal;
