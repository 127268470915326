import { useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import Profile from 'components/Profile';

import UserSearchModal from './UserSearchModal';

// eslint-disable-next-line complexity
const SelectUser = ({ user, handleSelect, required = false, disabled = false }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <UserSearchModal
        isOpen={modalOpen}
        handleModalClose={() => setModalOpen(false)}
        handleSelect={(user) => {
          setModalOpen(false);
          handleSelect(user);
        }}
      />
      <Typography variant="h6" sx={{ color: 'text.primary' }}>{`Introduction By ${
        required ? '*' : ''
      }`}</Typography>
      {isEmpty(user?._id) ? (
        <Typography sx={{ color: 'text.primary' }}>No user selected.</Typography>
      ) : (
        <Profile
          firstName={user?.firstName}
          lastName={user?.lastName}
          profileImage={user?.image}
        />
      )}
      <Box sx={{ paddingTop: '10px' }}>
        <Button
          disabled={disabled}
          onClick={() => setModalOpen(true)}
          variant="contained"
          sx={{ marginRight: '1rem' }}
        >
          {!isEmpty(user?._id) ? 'Change User' : 'Select User'}
        </Button>
        {!isEmpty(user?._id) && (
          <Button
            disabled={disabled}
            onClick={() => handleSelect({})}
            variant="contained"
          >
            Clear
          </Button>
        )}
      </Box>
    </>
  );
};

SelectUser.propTypes = {
  disabled: PropTypes.bool,
  handleSelect: PropTypes.func,
  required: PropTypes.bool,
  user: PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    image: PropTypes.string,
  }),
};

export default SelectUser;
