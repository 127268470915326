import { useCallback, useState, useEffect } from 'react';

import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import { DataGrid } from '@mui/x-data-grid';
import { isEmpty, noop, get } from 'lodash';
import PropTypes from 'prop-types';

import restService from 'services/restService';

import SearchCommitments from './SearchCommitments';

const columns = [
  {
    field: 'createdAt',
    headerName: 'Pledged On',
    flex: 1,
    valueGetter: (value, row) => get(row, 'createdAt'),
    exportGetter: (params) => get(params, 'createdAt'),
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    flex: 1,
    valueGetter: (value, row) => get(row, 'firstName'),
    exportGetter: (params) => get(params, 'firstName'),
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    flex: 1,
    valueGetter: (value, row) => get(row, 'lastName'),
    exportGetter: (params) => get(params, 'lastName'),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    valueGetter: (value, row) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(get(row, 'amount', 0)),
    exportGetter: (params) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(get(params, 'amount', 0)),
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    valueGetter: (value, row) => get(row, 'type'),
    exportGetter: (params) => get(params, 'type'),
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    valueGetter: (value, row) => get(row, 'email'),
    exportGetter: (params) => get(params, 'email'),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 1,
    valueGetter: (value, row) => get(row, 'phone'),
    exportGetter: (params) => get(params, 'phone'),
  },
  {
    field: 'street1',
    headerName: 'Street 1',
    flex: 1,
    valueGetter: (value, row) => get(row, 'address.street1'),
    exportGetter: (params) => get(params, 'address.street1'),
  },
  {
    field: 'street2',
    headerName: 'Street 2',
    flex: 1,
    valueGetter: (value, row) => get(row, 'address.street2'),
    exportGetter: (params) => get(params, 'address.street2'),
  },
  {
    field: 'city',
    headerName: 'City',
    flex: 1,
    valueGetter: (value, row) => get(row, 'address.city'),
    exportGetter: (params) => get(params, 'address.city'),
  },
  {
    field: 'state',
    headerName: 'State',
    flex: 1,
    valueGetter: (value, row) => get(row, 'address.state'),
    exportGetter: (params) => get(params, 'address.state'),
  },
  {
    field: 'postalCode',
    headerName: 'Postal Code',
    flex: 1,
    valueGetter: (value, row) => get(row, 'address.postalCode'),
    exportGetter: (params) => get(params, 'address.postalCode'),
  },
  {
    field: 'signed',
    headerName: 'Signed',
    flex: 1,
    valueGetter: (value, row) => get(row, 'signed'),
    exportGetter: (params) => get(params, 'signed'),
  },
  {
    field: 'rockPledgeId',
    headerName: 'Rock Pledge Id',
    flex: 1,
    valueGetter: (value, row) => get(row, 'rockPledgeId'),
    exportGetter: (params) => get(params, 'rockPledgeId'),
  },
  {
    field: 'story',
    headerName: 'Story',
    flex: 1,
    valueGetter: (value, row) => get(row, 'story'),
    exportGetter: (params) => get(params, 'story'),
  },
].map((column) => ({
  ...column,
  sortable: false,
  headerAlign: 'center',
  align: 'center',
}));

const SearchResults = ({
  rows,
  totalRowCount,
  currentPage = 0,
  setCurrentPage,
  pageSize,
  setPageSize,
  onPaginationChange = noop,
}) => {
  // eslint-disable-next-line no-magic-numbers
  const pageSizeOptions = [10, 25, 50, 99];
  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.pageSize === pageSize ? paginationModel.page : 0);

    setPageSize(paginationModel.pageSize);
    onPaginationChange();
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnFilter
        disableRowSelectionOnClick
        pagination
        paginationMode="server"
        pageSizeOptions={pageSizeOptions}
        onPaginationModelChange={handlePaginationModelChange}
        rowCount={totalRowCount}
        getRowId={(row) => row._id}
        paginationModel={{
          page: currentPage, // DataGrid uses zero-based page numbering
          pageSize,
        }}
        getRowHeight={() => 'auto'}
      />
    </div>
  );
};
SearchResults.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      type: PropTypes.string,
      fields: PropTypes.shape({
        title: PropTypes.string,
        published_at: PropTypes.string,
      }),
      featuredIndex: PropTypes.number,
    })
  ),
  totalRowCount: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func.isRequired,
  onPaginationChange: PropTypes.func,
};

const Commitments = () => {
  const [search, setSearch] = useState({
    email: '',
    firstName: '',
    lastName: '',
    notInRock: false,
  });
  const [commitments, setCommitments] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationChanged, setPaginationChanged] = useState(false);
  // eslint-disable-next-line no-magic-numbers
  const [pageSize, setPageSize] = useState(99);
  const [isExporting, setIsExporting] = useState(false);
  const [exportData, setExportData] = useState([]);

  const handleSearch = () => {
    fetchData(search);
  };

  const fetchData = useCallback(
    (searchQuery) => {
      let validSearchValues = Object.fromEntries(
        Object.entries(searchQuery).filter(([, v]) => v !== '')
      );
      restService
        .callEndpoint({
          endpoint: 'getCommitmentList',
          body: {
            query: validSearchValues,
            pageSize,
            page: currentPage,
            isExport: isExporting,
          },
          method: 'post',
        })
        .then((response) => {
          setCommitments(response?.commitments || []);
          setTotalRowCount(response?.totalCount || 0);
          setPaginationChanged(false);

          if (isExporting) {
            const mappedExportData = {
              headers: columns.map((column) => column.headerName),
              data: response?.commitments?.map((commitment) =>
                columns.map((column) => column.exportGetter(commitment))
              ),
            };

            setExportData(mappedExportData || []);
          }
        });
    },
    [currentPage, isExporting, pageSize]
  );

  useEffect(() => {
    if (paginationChanged) {
      fetchData(search);
    }

    if (isExporting) {
      setExportData([]);
      fetchData(search);
    }
  }, [fetchData, isExporting, paginationChanged, search]);

  useEffect(() => {
    fetchData(search);
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        justifyContent: 'center',
      }}
    >
      <h1>Commitments</h1>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <SearchCommitments
          onSearch={handleSearch}
          searchQuery={search}
          setSearchQuery={setSearch}
          exportData={exportData}
          handleRefresh={handleSearch}
          isExporting={isExporting}
          onExport={() => setIsExporting(true)}
          onExportComplete={() => setIsExporting(false)}
        />
        {!isEmpty(commitments) ? (
          <SearchResults
            rows={commitments}
            totalRowCount={totalRowCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            onPaginationChange={() => setPaginationChanged(true)}
          />
        ) : (
          <Alert variant="outlined" severity="info">
            There are no commitments yet.
          </Alert>
        )}
      </Box>
    </Box>
  );
};

Commitments.propTypes = {};

export default Commitments;
