import { useCallback, useEffect, useState } from 'react';

import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import { get, isEmpty } from 'lodash';

import restService from 'services/restService';

import NewPrayer from './NewPrayer';
import SearchResults from './SearchResults';

const defaultState = {
  modalOpen: false,
  prayerId: null,
  newData: {},
  action: null,
};

const Prayers = () => {
  const [prayersFound, setPrayersFound] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [state, setState] = useState(defaultState);
  const [showCreate, setShowCreate] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationChanged, setPaginationChanged] = useState(false);
  // eslint-disable-next-line no-magic-numbers
  const [pageSize, setPageSize] = useState(5);

  const handleModalClose = () => {
    setState(defaultState);
  };

  const handleUpdatePrayer = ({ action, prayerId }) => {
    if (action === 'update' && (!state.newData || isEmpty(state.newData))) {
      return;
    }
    restService
      .callEndpoint({
        endpoint: 'updateCrossroadsPrayer',
        body: { action, prayerId, updatedFields: state.newData },
        method: 'post',
      })
      .then(() => {
        fetchData();
        handleModalClose();
      });
  };

  const fetchData = useCallback(() => {
    restService
      .callEndpoint({
        endpoint: 'getCrossroadsPrayer',
        params: { pageSize, page: currentPage },
        method: 'get',
      })
      .then((data) => {
        const result = get(data, 'data.prayers');
        setPrayersFound(result || []);
        setTotalRowCount(get(data, 'data.totalCount', 0));
        setPaginationChanged(false);
      });
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (paginationChanged) {
      fetchData();
    }
  }, [fetchData, paginationChanged]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Modal open={state.modalOpen}>
        <Box>
          <Dialog
            open={state.modalOpen}
            onClose={handleModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Update Prayer'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to {state.action} this prayer?
              </DialogContentText>
              {!isEmpty(state.newData) ? (
                <DialogContentText sx={{ margin: '1rem 0 0 0' }}>
                  The following fields will be updated:
                  {Object.keys(state.newData).map((k) => (
                    <DialogContentText key={k}>{`    ${k}`}</DialogContentText>
                  ))}
                </DialogContentText>
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleModalClose}>Go Back</Button>
              <Button
                onClick={() => {
                  handleUpdatePrayer({
                    action: state.action,
                    prayerId: state.prayerId,
                  });
                }}
                autoFocus
              >
                {state.action} Prayer
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Modal>
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          margin: '0 0 2rem 0',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2">Crossroads Prayers</Typography>
        <IconButton onClick={fetchData} title="Refresh">
          <RefreshRoundedIcon color="primary" fontSize="large" />
        </IconButton>
        {showCreate ? (
          <Button variant="contained" onClick={() => setShowCreate(false)}>
            Show List
          </Button>
        ) : (
          <Button variant="contained" onClick={() => setShowCreate(true)}>
            Create
          </Button>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {showCreate ? (
          <NewPrayer refetchPrayers={fetchData} onComplete={() => setShowCreate(false)} />
        ) : (
          <SearchResults
            rows={prayersFound}
            totalRowCount={totalRowCount}
            setState={setState}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            onPaginationChange={() => setPaginationChanged(true)}
          />
        )}
      </Box>
    </>
  );
};

export default Prayers;
