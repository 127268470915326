import { useCallback, useEffect, useState } from 'react';

import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import DataTable from 'components/DataTable';
import Profile from 'components/Profile';
import restService from 'services/restService';

const defaultSearch = {
  text: '',
  title: '',
};

const ScriptureIntroSearchModal = ({ isOpen, handleModalClose, handleSelect }) => {
  // eslint-disable-next-line no-magic-numbers
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationChanged, setPaginationChanged] = useState(false);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [introductions, setIntroductions] = useState([]);
  const [search, setSearch] = useState(defaultSearch);

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      valueGetter: (value, row) => row?.title,
    },
    {
      field: 'text',
      headerName: 'Text',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ width: 200 }}>
          <Markdown rehypePlugins={[rehypeRaw]}>{params.row?.text}</Markdown>
        </Box>
      ),
    },
    {
      field: 'introductionBy',
      headerName: 'Introduction By',
      flex: 1,
      renderCell: (params) => {
        const user = params.row?.user?.profile;

        return !isEmpty(user) ? (
          <Profile
            firstName={user?.firstName}
            lastName={user?.lastName}
            profileImage={user?.image}
          />
        ) : null;
      },
    },
    {
      field: 'videoUrl',
      headerName: 'Video Url',
      flex: 1,
      valueGetter: (value, row) => row?.videoUrl,
    },
    {
      field: 'actions',
      flex: 1,
      type: 'actions',
      headerName: 'Actions',
      cellClassName: 'actions',
      renderCell: (params) => (
        <Button
          onClick={() => {
            setSearch(defaultSearch);
            setIntroductions();
            handleSelect(params.row);
          }}
          variant="contained"
          sx={{ margin: '5px' }}
        >
          Select
        </Button>
      ),
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  }));

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchData(search);
    }
  };

  const handleSearch = () => fetchData(search);

  const fetchData = useCallback(
    (searchQuery) => {
      let validSearchValues = Object.fromEntries(
        Object.entries(searchQuery).filter(([, v]) => v !== '')
      );

      restService
        .callEndpoint({
          endpoint: 'getDailyScriptureIntroductions',
          body: { pageSize, page: currentPage, query: validSearchValues },
          method: 'post',
        })
        .then((response) => {
          setIntroductions(response?.scriptureIntroductions ?? []);
          setTotalRowCount(response?.totalCount ?? 0);
          setPaginationChanged(false);
        });
    },
    [currentPage, pageSize]
  );

  useEffect(() => {
    if (paginationChanged) {
      fetchData(search);
    }
  }, [fetchData, paginationChanged, search]);

  useEffect(() => {
    fetchData(search);
  }, []); // eslint-disable-line

  return (
    <Modal
      open={isOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ backdropFilter: 'blur(15px)' }}
    >
      <Box
        sx={{
          position: 'absolute',
          flex: 1,
          padding: '2rem',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          height: '70%',
          bgcolor: 'background.paper',
          borderRadius: '2rem',
          overflowY: 'scroll',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: 'text.primary',
            marginBottom: '1rem',
            marginTop: '1rem',
          }}
        >
          Search for Scripture Introduction
        </Typography>
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <TextField
            label="Title"
            variant="outlined"
            sx={{ flexGrow: 1 }}
            value={search.title}
            onChange={(event) => setSearch({ ...search, title: event.target.value })}
            onKeyDown={handleKeyDown}
          />
          <TextField
            label="Text"
            variant="outlined"
            sx={{ flexGrow: 1 }}
            value={search.text}
            onChange={(event) => setSearch({ ...search, text: event.target.value })}
            onKeyDown={handleKeyDown}
          />
          <Button variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </Box>
        <Box sx={{ marginTop: '1rem' }}>
          <DataTable
            columns={columns}
            rows={introductions}
            totalRowCount={totalRowCount}
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onPaginationChange={() => setPaginationChanged(true)}
          />
        </Box>
      </Box>
    </Modal>
  );
};

ScriptureIntroSearchModal.propTypes = {
  isOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleSelect: PropTypes.func,
};

export default ScriptureIntroSearchModal;
