import { useState } from 'react';

import ArchiveIcon from '@mui/icons-material/ArchiveRounded';
import CancelIcon from '@mui/icons-material/CloseRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import SaveIcon from '@mui/icons-material/SaveRounded';
import AnswerIcon from '@mui/icons-material/SpellcheckRounded';
import { Box, Button } from '@mui/material';
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { cloneDeep, get, isEmpty, noop } from 'lodash';
import PropTypes from 'prop-types';

const SearchResults = ({
  rows,
  totalRowCount,
  setState,
  currentPage = 0,
  setCurrentPage,
  pageSize,
  setPageSize,
  onPaginationChange = noop,
}) => {
  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleArchiveClick = (id) => () => {
    setState({
      modalOpen: true,
      prayerId: id,
      action: 'archive',
    });
  };

  const handleAnswerClick = (id) => () => {
    setState({
      modalOpen: true,
      prayerId: id,
      action: 'answer',
    });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const getChangedRows = (newRow) => {
    const allFields = cloneDeep(newRow);
    delete allFields.prayer;
    delete allFields.spotlight;
    const spotlightFields = ['heading', 'subheading', 'buttonText'];
    const prayerFields = ['prayerText'];
    const updatedFields = {};
    Object.keys(allFields).forEach((key) => {
      if (spotlightFields.includes(key)) {
        if (newRow.spotlight[key] !== allFields[key]) {
          updatedFields[key] = allFields[key];
        }
      } else if (prayerFields.includes(key)) {
        if (newRow.prayer[key] !== allFields[key]) {
          updatedFields[key] = allFields[key];
        }
      }
    });
    return updatedFields;
  };

  const processRowUpdate = (newRow) => {
    const updatedFields = getChangedRows(newRow);
    if (!isEmpty(updatedFields)) {
      setState({
        modalOpen: true,
        prayerId: newRow.prayer._id,
        newData: updatedFields,
        action: 'update',
      });
    }

    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const renderViewImage = (params) => (
    <Button
      component="a"
      href={get(params, 'row.spotlight.backgroundImage')}
      target="_blank"
    >
      View Image
    </Button>
  );

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.pageSize === pageSize ? paginationModel.page : 0);

    setPageSize(paginationModel.pageSize);
    onPaginationChange();
  };

  // eslint-disable-next-line no-magic-numbers
  const pageSizeOptions = [5, 10, 20, 30];

  const columns = [
    {
      field: 'prayerText',
      headerName: 'Prayer Text',
      editable: true,
      flex: 3,
      valueGetter: (value, row) => get(row, 'prayer.prayerText', ''),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      valueGetter: (value, row) =>
        row.prayer.createdAt ? dayjs(row.prayer.createdAt) : '',
    },
    {
      field: 'prayedForCount',
      headerName: 'Prayed For Count',
      flex: 1,
      valueGetter: (value, row) => get(row, 'prayer.prayedForCount', 0),
    },
    {
      field: 'heading',
      editable: true,
      headerName: 'Heading',
      flex: 1,
      valueGetter: (value, row) => get(row, 'spotlight.heading', ''),
    },
    {
      field: 'subheading',
      headerName: 'Subheading',
      editable: true,
      flex: 1,
      valueGetter: (value, row) => get(row, 'spotlight.subheading', ''),
    },
    {
      field: 'buttonText',
      headerName: 'Button Text',
      editable: true,
      flex: 1,
      valueGetter: (value, row) => get(row, 'spotlight.buttonText', ''),
    },
    {
      field: 'backgroundImage',
      headerName: 'Background',
      align: 'left',
      flex: 1,
      renderCell: (params) => renderViewImage(params.row),
    },
    {
      field: 'startOn',
      headerName: 'Start On',
      flex: 1,
      valueGetter: (value, row) =>
        row.spotlight?.startOn ? dayjs(row.spotlight.startOn) : '',
    },
    {
      field: 'endOn',
      headerName: 'End On',
      flex: 1,
      valueGetter: (value, row) =>
        row.spotlight?.endOn ? dayjs(row.spotlight.endOn) : '',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              key="save"
              title="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              key="cancel"
              title="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            key="edit"
            title="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<ArchiveIcon />}
            label="Archive"
            title="Archive"
            key="archive"
            onClick={handleArchiveClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<AnswerIcon />}
            label="Answer"
            title="Answer"
            key="answer"
            onClick={handleAnswerClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={pageSizeOptions}
        pagination
        paginationMode="server"
        paginationModel={{
          page: currentPage, // DataGrid uses zero-based page numbering
          pageSize,
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        rowCount={totalRowCount}
        getRowId={(row) => row.prayer._id}
        disableColumnFilter
      />
    </Box>
  );
};

SearchResults.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      prayerText: PropTypes.string,
      createdAt: PropTypes.string,
      prayedForCount: PropTypes.number,
    })
  ),
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func.isRequired,
  totalRowCount: PropTypes.number,
  setState: PropTypes.func.isRequired,
  onPaginationChange: PropTypes.func,
};

export default SearchResults;
