import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
} from '@mui/material';
import PropTypes from 'prop-types';

const ActionDialog = ({
  confirmText,
  dialogText,
  handleConfirm,
  handleModalClose,
  isOpen = false,
  title,
}) => (
  <Modal open={isOpen}>
    <Box>
      <Dialog
        open={isOpen}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Go Back</Button>
          <Button onClick={handleConfirm} autoFocus>
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  </Modal>
);

ActionDialog.propTypes = {
  action: PropTypes.string,
  confirmText: PropTypes.string,
  dialogText: PropTypes.string,
  handleConfirm: PropTypes.func,
  handleModalClose: PropTypes.func,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
};

export default ActionDialog;
