import React, { useState } from 'react';

import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import PropTypes from 'prop-types';

import ImageUpload, { DEFAULT_IMAGE_URL } from 'components/ImageUpload';
import restService from 'services/restService';

const NewPrayer = ({ refetchPrayers, onComplete }) => {
  const [prayerText, setPrayerText] = useState('');
  const [heading, setHeading] = useState('');
  const [subheading, setSubheading] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [startOn, setStartOn] = useState(null);
  const [endOn, setEndOn] = useState(null);

  const handlePrayerTextChange = (event) => {
    setPrayerText(event.target.value);
  };

  const handleHeadingChange = (event) => {
    setHeading(event.target.value);
  };

  const handleSubheadingChange = (event) => {
    setSubheading(event.target.value);
  };

  const handleButtonTextChange = (event) => {
    setButtonText(event.target.value);
  };

  const handleStartOnChange = (event) => setStartOn(event);
  const handleEndOnChange = (event) => setEndOn(event);

  const handleCreatePrayer = async () => {
    restService
      .callEndpoint({
        endpoint: 'createCrossroadsPrayer',
        body: {
          prayerText,
          heading,
          subheading,
          buttonText,
          imageUrl: imageUrl || DEFAULT_IMAGE_URL,
          startOn,
          endOn,
        },
        method: 'post',
      })
      .then(() => {
        refetchPrayers();
        onComplete();
      });
  };

  const disableCreateButton = !prayerText || !heading;

  const renderEmptyImageUrlMessage = () => (
    <div>
      <Typography variant="body1">
        {
          'No image selected. Click "Upload Image" below. If you do not select an image we\'ll use'
        }
      </Typography>
      <Link target="_blank" rel="noopener" href={DEFAULT_IMAGE_URL}>
        {' this default image.'}
      </Link>
    </div>
  );

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: 2,
        }}
      >
        <TextField
          multiline
          fullWidth
          required
          label="Prayer Text"
          value={prayerText}
          onChange={handlePrayerTextChange}
        />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 2,
          }}
        >
          <TextField
            required
            label="Heading"
            value={heading}
            onChange={handleHeadingChange}
          />
          <TextField
            label="Subheading"
            value={subheading}
            onChange={handleSubheadingChange}
          />
          <TextField
            label="Button Text"
            value={buttonText}
            onChange={handleButtonTextChange}
          />
          <DateTimePicker
            label="Start On"
            value={startOn}
            onChange={handleStartOnChange}
          />
          <DateTimePicker label="End On" value={endOn} onChange={handleEndOnChange} />
        </Box>
        <ImageUpload setImageUrl={setImageUrl} renderEmpty={renderEmptyImageUrlMessage} />
      </Box>
      <Button
        variant="contained"
        size="large"
        sx={{ width: '100%' }}
        onClick={handleCreatePrayer}
        disabled={disableCreateButton}
      >
        Create Prayer
      </Button>
    </>
  );
};

NewPrayer.propTypes = {
  onComplete: PropTypes.func,
  refetchPrayers: PropTypes.func,
};

export default NewPrayer;
